import React from "react"
import Layout from "../components/layout"

import buyMeACoffeeLogo from "../images/buy-me-a-coffee-logo.png";
import patreonLogo from "../images/patreon-logo-coral.png";
import payPalLogo from "../images/pay-pal-logo.png";

const donationOptions = [
  {
    name: 'buy-me-a-coffee',
    text: 'Buy me a coffee',
    url: 'https://www.buymeacoffee.com/refresh1',
    logo: {
      alt: 'Buy me a coffee Logo',
      src: buyMeACoffeeLogo
    }
  }, {
    name: 'patreon',
    text: 'Donate via Patreon',
    url: 'https://www.patreon.com/refresh1',
    logo: {
      alt: 'Patreon Logo',
      src: patreonLogo
    }
  }, {
    name: 'paypal',
    text: 'Donate via PayPal',
    url: 'https://www.paypal.com/paypalme/adorepayments',
    logo: {
      alt: 'PayPal Logo',
      src: payPalLogo
    }
  }, 
]

const DonationOption = ({ option }) => (
  <div className="donation-option">
    <a target="_blank" rel="noopener noreferrer" href={option.url}>
     <span className="link-spanner"></span>
    </a>
    <img src={option.logo.src} alt={option.logo.alt} />
    <h4>{option.text}</h4>
  </div>
)

export default function Donate() {
  return (
    <Layout>
      <div className="donate full-page-module">
        <div className="donation-options">
          {donationOptions.map(option => 
            <DonationOption 
              key={option.name}
              option={option}
            />)}
        </div>
      </div>
    </Layout>
  )
}